<template>
  <v-dialog v-model="shower" scrollable persistent max-width="550px">
    <v-card>
      <v-card-text style="height: auto; overflow-y:hidden;">
        <!-- <v-layout class="mt-3 mb-n6" v-if="resolutionScreen < 500">
          <v-flex lg12 class="text-right">
            <v-icon @click="$emit('closedialog')" color="red">mdi-close</v-icon>
          </v-flex>
        </v-layout> -->
        <div class="ma-4 pa-0 text-center">
          <img
            v-if="lang_ === 'th'"
            src="@/assets/img/CSAT_TH.png"
            :width="resolutionScreen >= 400 ? '300px' : '250px'"
            :height="resolutionScreen >= 400 ? '180px' : '150px'"
            alt="One Box"
          />
          <img
            v-else
            src="@/assets/img/CSAT_ENG2.png"
            :width="resolutionScreen >= 400 ? '300px' : '250px'"
            :height="resolutionScreen >= 400 ? '180px' : '150px'"
            alt="One Box"
          />
        </div>
        <div
          class="text-center"
        >
          <br />
          <span
            :style="
              resolutionScreen < 500 ? 'font-size: 18px;' : 'font-size: 20px;'
            "
          >
          {{$t("evaluategialog.evaluate_title")}}
          </span>
          <br />
          <span style="font-size: 16px;">
            {{$t("evaluategialog.evaluate_title1")}}
          </span>
        </div>
        <br />
        <br />
        <div class="text-center">
          <v-btn
            color="red"
            outlined
            @click="fn_checkevaluate()"
          >
          {{ $t("evaluategialog.evaluate_btn1") }}</v-btn
          >
          <v-btn
            class="ma-3 white--text"
            :color="color.theme"
            @click="fn_evaluation()"
          >
          {{ $t("evaluategialog.evaluate_btn2") }}</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
export default {
  props: ["show", "taxinvoicedata"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
      lang_:"",
      statusevaluation:"",
      eva_expired:""

    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          this.lang_ = localStorage.getItem('lang')
        }
        return this.show;
      },
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
  },
  methods: {
  async fn_evaluation(){
  console.log("this.dataAccesstoken",this.dataAccesstoken);
  console.log("process.env",process.env);
  let token = localStorage.getItem("token") ? localStorage.getItem("token") : sessionStorage.getItem("token") ? sessionStorage.getItem("token") : VueCookies.get("token");
  let token_ = token ? window.atob(token) : "";
  // let accesstoken_ = window.atob(VueCookies.getItem("token"));
  let accesstoken_ = token_
  console.log("accesstoken_",accesstoken_);
  let user_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
    user_id = window.atob(user_id.toString(CryptoJS.enc.Utf8))
    let payload = {
      // accesstoken: this.dataAccesstoken
      accesstoken: accesstoken_
    }
    await this.axios
    .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getsharetoken_from_accesstoken", payload)
    .then((response) => {
      return new Promise((resolve, reject) => {
        console.log("response",response);          
        if (response.data.status === "OK") {
          if (process.env.VUE_APP_ENVIRONMENT === "PRODUCTION" ) {
           
            let redirect_url
            if(this.dataAccountActive.type === "Business"){
              localStorage.setItem("temp_folder_id",this.dataAccountActive.business_info.my_folder_id);
              localStorage.setItem("temp_biz_id",this.dataAccountActive.business_info.id);
              localStorage.setItem("temp_tax_id",this.dataAccountActive.taxid);
              localStorage.setItem("temp_branch_no",this.dataAccountActive.business_info.branch_no);
              // redirect_url = process.env.VUE_APP_REDIREC_EVALUATION + accesstoken_ + "/biz_id=" + this.dataAccountActive.business_info.id + "/tax_id=" + this.dataAccountActive.taxid + "/branch_no" + this.dataAccountActive.business_info.branch_no;
              redirect_url = process.env.VUE_APP_REDIREC_EVALUATION + accesstoken_;
              
            }else{
              localStorage.removeItem("temp_biz_id");
              localStorage.removeItem("temp_tax_id");
              localStorage.removeItem("temp_branch_no");
              localStorage.removeItem("temp_folder_id");
              
              redirect_url = process.env.VUE_APP_REDIREC_EVALUATION + accesstoken_;
            }
              let shared_token = response.data.shared_token.slice(0,10);
              window.location.href = process.env.VUE_APP_EVALUATION + shared_token + "&service_id=26" + "&account_id=" + user_id + "&biz_id=395082882048" + "&redirect_url=" + redirect_url + "&service_name=Onebox" + "&type_topic=คำถามประเมินความพึงพอใจสำหรับผู้ใช้งาน" + "&role=user";
          }else{
              // window.location.href = "http://203.151.33.161/satisfaction/survey?shared_token=1234&service_id=401&role=admin&account_id=0112939543&biz_id=395082882048&redirect_url=http%3A%2F%2Flocalhost%3A8080%2Fmanage%2Fdashboar%22%20class%3D%22linkified%22%20target%3D%22_blank%22%3Ehttp%3A%2F%2F203.151.33.161%2Fsatisfaction%2Fsurvey%3Fshared_token%3D1234&redirect_url=http%3A%2F%2Flocalhost%3A8080%2Fmanage%2Fdashboard";
              window.location.href = "http://203.151.33.161/satisfaction/survey?shared_token=1234&service_id=79&role=user" + "&account_id=" + user_id + "&biz_id=395082882048&redirect_url=" + process.env.VUE_APP_REDIREC_EVALUATION + this.dataAccesstoken ;
          }

        }
      });
    })
    .catch((err) => {})
    
  },
  fn_no_evaluate(){
    let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

    let payload = {
      one_id: one_id,
      status_evaluate: "w"
    };
    console.log("payload", payload);
    this.axios
      .post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
        "/api/insert_user_evaluate",
        payload,

      )
      .then((response) => {
        console.log("res",response);
        if (response.data.status === "OK") {
          this.$emit('closedialog');
          // window.location.reload(true);
          VueCookies.set("iconeva",true);
          this.$emit('reload');
          
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });

  },
  fn_checkevaluate(){
      let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

      let payload = {
        one_id: one_id
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
          "/api/get_user_evaluate",
          payload,
        )
        .then((response) => {
          console.log("response",response);
          if (response.data.status === "OK") {
            this.statusevaluation = response.data.data.status_evaluate
            this.eva_expired = response.data.data.evaluate_expired
            if(this.statusevaluation === 'Y' && this.eva_expired === 'N'){
              this.$emit('closedialog');
            }else{
              this.fn_no_evaluate();
            }

          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
  }


  },
};
</script>

<style></style>
